<template>
    <v-card>
        <div v-if="!item">
            <v-card-text>Fehler beim Laden...</v-card-text>
        </div>
        <div v-else>
        <v-container>
            <v-layout wrap>
                <v-flex xs12 md5>
                    <div class="image-wrapper">
                        <v-img :src="img" max-height="300" contain />
                    </div>
                </v-flex>
                <v-flex xs12 md7>
                    <h2 class="mt-10">{{ item.name }}</h2>
                    <p>{{ item.manufacturer }}</p>
                    <p class="item-num">
                        Artikelnummer:<br>
                        <strong>{{ item.itemnum }}</strong>
                    </p>
                    <p class="item-num" v-if="item.barcode">
                        Barcode:<br>
                        <strong>{{ item.barcode }}</strong>
                    </p>
                    <p>
                        Verfügbare Menge:<br>
                        <strong>{{ item.stock.amount > 0 ? item.stock.amount : 0 }}</strong>
                    </p>
                </v-flex>
            </v-layout>
        </v-container>
        <v-divider />
        <v-tabs background-color="#E8E8E8">
            <v-tab href="#tab-description">Beschreibung</v-tab>
            <v-tab href="#tab-documents">Dokumente</v-tab>
            <v-tab href="#tab-videos" v-if="item.extdata.ytlink">Video</v-tab>

            <v-tab-item value="tab-description">
                <v-card
                    flat
                    tile
                >
                    <v-card-text 
                        v-if="config.field_definitions && config.field_definitions.description_field"
                        v-html="item.extdata.shop_description"
                    >
                    </v-card-text>
                    <v-card-text 
                        v-else
                        class="truncated-text pt-0"
                    >
                        {{ item.description }}
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-documents">
                <v-card
                    flat
                    tile
                >
                    <v-card-text v-if="item.extdata.document">
                        <a :href="item.extdata.document" target="_blank">Produktdatenblatt2</a>
                    </v-card-text>
                    <v-card-text else>Kein Produktdatenblatt verfügbar.</v-card-text>
                </v-card>
                <v-card
                    flat
                    tile
                >
                  <v-card-text v-if="item.extdata.document2">
                        <a :href="item.extdata.document2" target="_blank">Bedienungsanleitung</a>
                    </v-card-text>
                    <v-card-text else>Keine Bedienungsanleitung verfügbar.</v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-videos" v-if="item.extdata.ytlink">
                <v-card
                    flat
                    tile
                >
                    <v-card-text>
                        <div class="videoWrapper">
                            <iframe width="560" height="315" :src="'https://www.youtube.com/embed/' + item.extdata.ytlink" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>
        </div>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'item-detail',

    props: ['item', 'img'],

    methods: {
    },

    computed: {
        ...mapGetters("app", ["config"])
    }
}
</script>

<style lang="scss" scoped>
.image-wrapper {
    margin-right: 1rem;
}
.image-wrapper img {
    max-width: 100%;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>
