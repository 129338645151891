<template>
    <v-container fluid>
        <!-- Customer selection/search -->
        <CustomerSelection />

        <v-row class="sticky">
            <v-col cols="6">
                <v-select 
                    clearable 
                    :items="categories" 
                    placeholder="Kategorie auswählen" 
                    v-model="category"
                    @change="searchItems()"
                    @click:clear="searchItems('tag')" 
                />
            </v-col>
            
            <v-col cols="6">
                <v-text-field 
                    clearable 
                    v-model="search" 
                    placeholder="Suchbegriff, Barcode oder Artikelnummer ..." 
                    @keyup.enter="searchItems()"
                    append-icon="mdi-magnify"
                    @click:append="searchItems()"
                    @click:clear="searchItems('term')" 
                />
            </v-col>
        </v-row>

        <v-row v-if="products.data && products.data.length > 0">
            <v-col 
                v-for="product in products.data" 
                :key="product.item" 
                cols="12" 
                sm="6" 
                md="4" 
                lg="3" 
                xl="2"
            >
                <item-card :item="product" />
            </v-col>
        </v-row>
        <v-alert v-else type="info" color="#303a4f">
            <span v-if="!search">
                Aktuell keine Produkte für Sie vorhanden.
                <span v-if="accounts && accounts.length > 0">Wählen Sie einen Kunden.</span>
            </span>
            <span v-else>
                Keine Produkte für Ihre Suche nach <strong>{{ search }}</strong> gefunden.
            </span>
        </v-alert>

        <div class="text-center mt-10">
            <v-pagination
                v-model="page"
                :length="pages"
            ></v-pagination>
        </div>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ItemCard from "@/modules/store/components/item/ItemCard";
import CustomerSelection from "@/modules/store/account/components/CustomerSelection";

export default {
    name: 'Home',

    components: {
        ItemCard,
        CustomerSelection,
    },

    data: () => ({
        page: 1,
        account: null,
        category: null,
        search: null,
    }),

    computed: {
        ...mapGetters("auth", ["userId"]),
        ...mapGetters("app", ["config"]),
        ...mapGetters("cart", ["cart"]),
        ...mapGetters("product", ["products"]),
        ...mapGetters("account", ["userAccount", "selectedAccount"]),

        accounts() {
            return this.userAccount?.accesslist;
        },

        categories() {
            return this.config.categories ? this.config.categories : [];
        },

        pages() {
            return this.products.pages;
        }
    },

    watch: {
        page(val) {
            this.changePage((val - 1) * this.products.limit);
        },

        selectedAccount() {
            this.init();
        }
    },

    created() {
        this.init();
    },

    methods: {
        ...mapActions("product", ["getProductList"]),
        ...mapActions("account", ["getAssignedAccount", "setAccount", "unsetAccount"]),

        init() {
            let accountId = this.selectedAccount ? this.selectedAccount.ID : this.userId;

            this.search = null;

            if (this.$route.params.articleNumber) {
                this.search = this.$route.params.articleNumber;
                this.searchItems();
            }

            this.getProductList({ limit: 50, offset: 0, search: this.search, tag: this.category, account: accountId });
        },

        changePage(offset) {
            let accountId = this.selectedAccount ? this.selectedAccount.ID : this.userId;

            this.getProductList({ limit: 50, offset: offset, search: this.search, tag: this.category, account: accountId });
        },

        unsetFilter() {
            this.unsetAccount();
            this.account = null;

            this.init();
        },

        searchItems(initValue = "") {
            if (initValue === "term") {
                this.search = null;
            }

            if (initValue === "tag") {
                this.tag = null;
            }

            let accountId = this.selectedAccount ? this.selectedAccount.ID : this.userId;
            this.getProductList({ limit: 50, offset: 0, search: this.search, tag: this.category, account: accountId });
        },
    }
}
</script>

<style lang="scss">
.sticky {
    background: #FFF;
    position: sticky;
    top: 40px;
    z-index: 2;
}
</style>
