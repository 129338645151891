import ApiService from "./api.service.js";

function ImageError(status, message) {
    this.status = status;
    this.message = message;
}

const ImageService = {
    getImageSrc: async function(itemId) {
        try {
            ApiService.setHeader();

            const response = await ApiService.get(process.env.VUE_APP_REQUEST_URL + "/product/" + itemId  +  "/image");

            return response.data;
        } catch (e) {
            throw new ImageError(e.response.status, e.response.data);
        }
    },
};

export default ImageService;
export { ImageService, ImageError };