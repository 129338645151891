<template>
    <v-card-actions>
        <v-btn icon @click.stop="$emit('show-product-detail')">
            <v-icon>mdi-eye</v-icon>
        </v-btn>
        <v-spacer />
        <v-divider class="ml-1 mr-1" vertical />
        <v-spacer />
        <item-quantity
            :amount="quantity"
            :vpe="vpe"
            :obligatedVpe="obligatedVpe"
            :allowZero="true"
            @on-change-quantity="onChangeQuantity"
        />
        <v-spacer />
        <v-divider class="ml-1 mr-2" vertical />
        <v-icon v-if="quantity > 0" slot="append" @click.stop="onRemoveItem">mdi-close</v-icon>
        <v-icon color="primary" class="mr-2" slot="append" @click.stop="onAddToCart">mdi-cart</v-icon>
    </v-card-actions>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ItemQuantity from './ItemQuantity';

export default {
  name: 'ItemAddToCart',

  components: {
    ItemQuantity
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    vpe: {
      type: Number,
      required: true
    },
  },

  data: () => ({
    quantity: 0
  }),

  computed: {
    ...mapGetters('persistedCart', ['cart']),
    
    obligatedVpe() {
        return this.item.extdata.e_VPE && this.item.extdata.e_VPE.match(/[*]/) ? true : false
    }
  },

  created() {
    this.quantity = this.amount(this.item.item) ? this.amount(this.item.item) : 0;
  },

  methods: {
    ...mapActions('persistedCart', ['addItemToCart', 'removeItemFromCart']),

    onAddToCart() {
      if (this.quantity === 0) {
        if (this.obligatedVpe) {
          this.quantity = this.vpe;
        } else {  
          this.quantity = 1;
        }
      }

      const item = {
        ...this.item,
        amount: this.quantity
      };

      this.addItemToCart(item);
    },

    onRemoveItem() {
      this.quantity = 0;

      // TODO: get line item id from cart and replace 'lineItem' + this.item.ID
      this.removeItemFromCart({
        lineItemId: 'lineItem' + this.item.item
      });
    },

    onChangeQuantity(quantity) {
      if (quantity === undefined || quantity === null || isNaN(quantity)) {
        return;
      }

      if (quantity <= 0) {
        this.quantity = 0;

        this.removeItemFromCart({
          lineItemId: 'lineItem' + this.item.item
        });

        return;
      }

      this.quantity = quantity;

      if (!this.quantity) {
        this.quantity = this.amount(this.item.item) ? this.amount(this.item.item) : 1;
      }

      if (this.quantity < 0) {
        this.quantity = this.amount(this.item.item) ? this.amount(this.item.item) : 0;
      }

      if (this.obligatedVpe) {
        let quantity = parseInt(this.quantity)/this.vpe;
        this.quantity = Math.ceil(quantity) * this.vpe;

        this.$emit('update-quantity');
      }

      this.onAddToCart();
    },

    increase() {
      this.quantity += this.vpe;
    },

    decrease() {
      if (this.quantity > 0) {
        this.quantity -= this.vpe;
      }
    },

    amount(itemId) {
      let cartLineItem = this.cart.data.lineItems[`lineItem${itemId}`];

      if (cartLineItem) {
        return cartLineItem.amount;
      }

      return 0;
    },
  }
}
</script>