<template>
    <v-card outlined class="item-card">
        <v-img class="mx-5 my-5" contain @click.stop="itemDialog = !itemDialog" height="200" :src="imageSrc" v-if="(item.extdata.shop_primary_image || item.image)" />
        <v-sheet @click.stop="itemDialog = !itemDialog" height="200" color="#EEE" v-else/> 
        <v-badge class="x-large-badge" overlap icon="mdi-cart" :value="amount(item.item)">
            <v-card-text class="pb-0">
                Artikelnummer:
                {{ item.itemnum }}
            </v-card-text>
            <v-card-title style="word-break: normal" class="pt-0">{{ item.name }}</v-card-title>
        </v-badge>
    
        <v-card-text v-html="this.desc"  
            v-if="config.field_definitions && config.field_definitions.description_field_short"
            class="truncated-text pt-0 word-break:normal  text-overflow: ellipsis"
        ></v-card-text>
        
        <v-card-text class="pt-0 pb-0">
            <div class="price-info" v-if="item.originalprice > item.sellingprice">
                <div class="old-price">
                    <span>{{ toPrice(item.originalprice) }}</span>
                    <span v-if="item.unit">/{{ unitMap[item.unit] }}</span>
                </div>
                <div class="new-price">
                    <span>{{ toPrice(item.sellingprice) }}</span>
                    <span v-if="item.unit">/{{ unitMap[item.unit] }}</span>
                </div>
                 <div class="pt-0 pb-0">
                    <i v-if="item.extdata.e_nettoweight"> ({{ toPrice((1 / item.extdata.e_nettoweight) * item.sellingprice)}} pro {{item.extdata.e_unit}})</i>   
                </div>
            </div>

            <div class="price-info" v-else>
                <div class="old-price">
                    -
                </div>
                <div class="price">
                    <span>{{ toPrice(item.sellingprice) }}</span>
                    <span v-if="item.unit">/{{ unitMap[item.unit] }}</span>
                </div>
                <div class="pt-0 pb-0">
                    <i v-if="item.extdata.e_nettoweight"> ({{ toPrice((1 / item.extdata.e_nettoweight) * item.sellingprice)}} pro {{item.extdata.e_unit}})</i>   
                </div>
                
            </div>

            <div>
                <i v-if="item.extdata.e_VPE">VPE: {{ item.extdata.e_VPE }} </i>
                <i>({{ item.stock.amount > 0 ? item.stock.amount : 0 }} auf Lager)</i>
            </div>
        </v-card-text>
        <v-card-text class="pt-0">
            Lieferzeit:
            {{ deliveryTime }}
        </v-card-text>
        
        <v-divider />

        <item-add-to-cart
            :item="item"
            :vpe="vpe"
            @update-quantity="snackbar = true"
            @show-product-detail="itemDialog = true"
        />

        <v-dialog max-width="750" v-model="itemDialog">
            <v-card class="mb-n1" elevation="0" tile>
                <v-card-actions>
                    <v-spacer />
                    <v-btn icon @click.stop="itemDialog = !itemDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
            <item-detail :item="item" :img="imageSrc" />
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            top
            right
            color="primary"
            transition="slide-x-reverse-transition"
        >
            Menge wurde auf die nächste VPE aufgerundet!
        </v-snackbar>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { ImageService } from "@/services/image.service";
import ItemDetail from "@/modules/store/components/item/ItemDetail";
import ItemAddToCart from "./ItemAddToCart";

export default {
    name: "item-card",

    components: {
        ItemDetail,
        ItemAddToCart
    },

    props: ["item"],

    data: () => ({
        imageSrc: "default",
        itemDialog: false,
        quantity: 0,
        snackbar: false
    }),

    created() {
        this.desc = this.item.extdata.shop_short_description

        if (typeof this.item.extdata.shop_primary_image == "undefined") {    
            if (!this.item.image) {
                return;
            }
            ImageService.getImageSrc(this.item.item)
                .then((res) => {
                    this.imageSrc = "data:image/png;base64," + res;
                })
             } else {
            
            this.imageSrc = this.item.extdata.shop_primary_image;
        }
    },

    computed: {
        ...mapGetters("app", ["config"]),

        vpe() {
            if (!this.item.extdata.e_VPE) return 1;

            return this.item.extdata.e_VPE
                ? parseInt(this.item.extdata.e_VPE.replace('*', '')) 
                : 1;
        },

        unitMap() {
            if (this.config == undefined) {
                return "?";
            }

            return this.config.unit_map != undefined ? this.config.unit_map : "?";
        },

        deliveryTime() {
            let deliveryTime = this.config.shipping.outofstoc;

            if (this.item.stock.amount > 0) {
                deliveryTime = this.item.extdata.e_delivery_onstock 
                    ? this.item.extdata.e_delivery_onstock 
                    : this.config.shipping.default;
            }

            if (this.item.stock.amount <= 0) {
                deliveryTime = this.item.extdata.e_delivery_outofstock
                    ? this.item.extdata.e_delivery_outofstock
                    : this.config.shipping.outofstock; 
            }

            return deliveryTime;
        }
    },

    methods: {
        toPrice(sellingprice) {
            return parseFloat(sellingprice).toFixed(2).replace('.',',') + "€";
        },

        amount(itemId) {
            let cartLineItem = _.find(this.cart, ["item", itemId]);

            if (cartLineItem) {
                return cartLineItem.amount;
            }
            return 0;
        },
    }
}
</script>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.item-card {
    .v-card__title {
        font-weight: 300;
        height: 70px;
        overflow: hidden;
    }
    .truncated-text {
        height: 115px;
        overflow: hidden;
    }

    .x-large-badge .v-badge__badge {
        transform: scale(2);
    }

    .old-price {
        text-decoration: line-through;
    }

    .price, .new-price {
        font-size: 1.5rem;
    }

    .new-price {
        color: rgb(214, 69, 69);
    }
}
</style>